


html {
    min-height: 100%;
    background: $solitude;
}

body {
    margin: 0;
    --header-height: 50px;
    --header-title-height: 35px;
    font-family: 'Roboto';
    font-weight: 300;
    color: $rhino;
    min-height: 100vh;
}

#root {
    min-height: 100vh;
}

.page {
    display: flex;
    width: 100%;
    min-height: 100vh;
    flex-direction: column;
}

.body-container {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    padding: 0 $grid-unit;
    width: 100%;
    max-width: 1400px;
    min-height: 100vh;
}

.body-container .body-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1 1 auto;
    min-height: 800px;
}

.pagetitle {
    display: flex;
    flex: 1 1 auto;
    padding-left: 20px;
    align-items: center;
    font-size: 30px;
    max-height: 80px;
    min-height: 80px;
}

.main-title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
}

.main-column {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: $grid-unit * 1.5;
}